@font-face {
    font-family: 'MintGrotesk';
    src: url('./MintGrotesk/MintGrotesk-Thin.woff2') format('woff2'),
    url('./MintGrotesk/MintGrotesk-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'MintGrotesk';
    src: url('./MintGrotesk/MintGrotesk-Regular.woff2') format('woff2'),
    url('./MintGrotesk/MintGrotesk-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'MintGrotesk';
    src: url('./MintGrotesk/MintGrotesk-Bold.woff2') format('woff2'),
    url('./MintGrotesk/MintGrotesk-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'MintGrotesk';
    src: url('./MintGrotesk/MintGrotesk-Black.woff2') format('woff2'),
    url('./MintGrotesk/MintGrotesk-Black.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow';
    src: url('./Barlow/Barlow-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow';
    src: url('./Barlow/Barlow-Regular.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow';
    src: url('./Barlow/Barlow-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow';
    src: url('./Barlow/Barlow-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow';
    src: url('./Barlow/Barlow-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Barlow';
    src: url('./Barlow/Barlow-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}